import {SerializedError, createReducer} from '@reduxjs/toolkit'
import {resetFilter, setFilter, setLocation, toggleTag} from '../actions/filter'
import {getSchedule, getScheduleData} from '../actions/schedule'

const initialState: SerializedError = null

export const error = createReducer(initialState, builder => {
  builder
    .addCase(getSchedule.rejected, (_state, action) => action.error)
    .addCase(getScheduleData.rejected, (_state, action) => action.error)
    .addCase(setLocation.rejected, (_state, action) => action.error)
    .addCase(resetFilter.rejected, (_state, action) => action.error)
    .addCase(toggleTag.rejected, (_state, action) => action.error)
    .addCase(setFilter.rejected, (_state, action) => action.error)
})

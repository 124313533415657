import {MEMBERS_APP_ID} from '@wix/wix-events-commons-statics'
import {IWixAPI} from '@wix/yoshi-flow-editor'

export const getMembersAPI = (wixCodeApi: IWixAPI) => wixCodeApi.site.getPublicAPI(MEMBERS_APP_ID)

export const getCurrentMemberId = (wixCodeApi: IWixAPI) => {
  const currentUser = wixCodeApi.user.currentUser
  if (currentUser.loggedIn) {
    return currentUser.id
  }
  return null
}

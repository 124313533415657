import type {AnyAction} from 'redux'
import {createBookmark, deleteBookmark} from '../../actions/schedule'
import type {State} from '../../reducers'

export const handleScheduledItemBookmarkClick = (state: State, action: AnyAction) => {
  const createBookmarkAction = createBookmark.pending.match(action)

  if (createBookmarkAction || deleteBookmark.pending.match(action)) {
    const {origin, itemId} = action.meta.arg
    return {
      evid: 216,
      schedule_item_id: itemId,
      status: createBookmarkAction ? 'on' : 'off',
      origin,
      event_id: state.event.id,
    }
  }
}

import {EVENTS_APP_ID} from '@wix/wix-events-commons-statics'
import {ControllerParams} from '@wix/yoshi-flow-editor'

export const instanceGetterFactory = (controller: ControllerParams['controllerConfig']) => {
  let instance = controller.appParams.instance
  onInstanceChanged(controller, newInstance => (instance = newInstance))
  return () => instance
}

export const onInstanceChanged = (
  controller: ControllerParams['controllerConfig'],
  callback: (instance: string) => void,
) => controller.wixCodeApi.site.onInstanceChanged(event => callback(event.instance), EVENTS_APP_ID)

import type {BiParams, CreateBiMiddlewareParams, EventMap} from './interfaces'

export const createUouBiMiddlewareWithBiParams = (params: BiParams, events: EventMap) => {
  if (params.wixCodeApi.window.viewMode === 'Editor') {
    return noop
  }
  return createMiddleware({
    bi: params.flowAPI.bi,
    eventMap: events.eventMap,
    biParams: params,
  })
}

const noop = () => next => action => next(action)

const createMiddleware = ({bi, eventMap, biParams}: CreateBiMiddlewareParams) => {
  const log = (action, store) => {
    const transformEvent = eventMap[action.type]

    if (transformEvent) {
      const biEvent = transformEvent(store.getState(), action, biParams)

      if (biEvent) {
        const {evid, ...params} = biEvent

        bi.report({src: 68, evid, params})
      }
    }
  }
  return store => next => action => {
    if (action) {
      log(action, store)
    }

    return next(action)
  }
}

import type {AnyAction} from 'redux'
import {schedulePageLoaded} from '../../actions/page-loaded'
import type {State} from '../../reducers'

export const handleSchedulePageLoaded = (state: State, action: AnyAction) => {
  if (schedulePageLoaded.match(action)) {
    return {
      evid: 191,
      event_id: state?.event?.id ?? null,
      schedule_item_count: action.payload.totalScheduleItems,
    }
  }
}

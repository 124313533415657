import {ControllerParams} from '@wix/yoshi-flow-editor'
import {getEventDetailsRelativeUrl} from '../services/location'
import {createAsyncAction} from '../services/redux-toolkit'

export const navigateToEventDetails = createAsyncAction(
  'NAVIGATE',
  async (_, {extra: {controllerParams}, getState}) => {
    const state = getState()
    const url = await getEventDetailsRelativeUrl(controllerParams, state.event)
    const wixCodeApi = (controllerParams as ControllerParams).controllerConfig.wixCodeApi
    wixCodeApi.location.to(url)
  },
)

export type NavigateToEventDetails = typeof navigateToEventDetails

import {createAsyncThunk} from '@reduxjs/toolkit'
import {
  EVENTS_APP_ID,
  GROUPS_APP_ID,
  GROUPS_SECTION_ID,
  PAID_PLANS_APP_ID,
  PAID_PLANS_SECTION_ID,
  SCHEDULE_SECTION_ID,
} from '@wix/wix-events-commons-statics'
import {IWixAPI, IWixStatic} from '@wix/yoshi-flow-editor'
import {createAsyncAction} from '../../settings-commons/services/redux-toolkit'
import {
  getInstalledAppsState,
  isAppSectionInstalled,
  isEventsInMembersInstalled,
  isMembersInstalled,
} from '../services/installed-apps'
import {InstalledAppsState} from '../types/state'
import {UPDATE_SITE_SETTINGS} from './site-settings'

export const checkInstalledAppsWixSDK = createAsyncThunk<InstalledAppsState, {Wix: IWixStatic}>(
  'CHECK_INSTALLED_APPS_WIX_SDK',
  async ({Wix}) => {
    const [members, schedule, groups, paidPlans, eventsInMembersInstalled] = await Promise.all([
      isMembersInstalled(Wix),
      isAppSectionInstalled(Wix, {sectionId: SCHEDULE_SECTION_ID, appDefId: EVENTS_APP_ID}),
      isAppSectionInstalled(Wix, {sectionId: GROUPS_SECTION_ID, appDefId: GROUPS_APP_ID}),
      isAppSectionInstalled(Wix, {sectionId: PAID_PLANS_SECTION_ID, appDefId: PAID_PLANS_APP_ID}),
      isEventsInMembersInstalled(Wix),
    ])

    return {members, schedule, groups, paidPlans, eventsInMembersInstalled}
  },
)

export const checkInstalledAppsWixCodeApi = createAsyncThunk<InstalledAppsState, {wixCodeApi: IWixAPI}>(
  'CHECK_INSTALLED_APPS_WIX_CODE_API',
  async ({wixCodeApi}) => getInstalledAppsState(wixCodeApi),
)

export const cancelMembersPromo = createAsyncAction(
  'CANCEL_MEMBERS_PROMO',
  async (_, {dispatch, extra: {serverApi}}) => {
    const payload = await serverApi.updateSiteSettings({firstTimeCompleted: true})
    dispatch({type: UPDATE_SITE_SETTINGS.SUCCESS, payload})
  },
)

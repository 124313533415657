import type {AnyAction} from 'redux'
import {setFilter, setLocation, toggleTag} from '../../actions/filter'
import type {State} from '../../reducers'

export const handleScheduleFilterApplied = (state: State, action: AnyAction) => {
  const setFilterAction = setFilter.fulfilled.match(action)
  const setLocationAction = setLocation.fulfilled.match(action)
  const toggleTagAction = toggleTag.fulfilled.match(action)

  if (setLocationAction || toggleTagAction || setFilterAction) {
    let is_place_filtered = setLocationAction
    let is_tags_filtered = toggleTagAction

    if (setFilterAction) {
      const {tags, location} = action.meta.arg.data
      is_place_filtered = Boolean(location)
      is_tags_filtered = Boolean(tags) && Boolean(Object.values(tags).filter(Boolean).length)
    }

    return {
      evid: 192,
      event_id: state.event.id,
      is_place_filtered,
      is_tags_filtered,
    }
  }
}

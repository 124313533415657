import type {AnyAction} from 'redux'
import {logout, promptLogin} from '../../actions/member'
import type {State} from '../../reducers'

export const handleMembersLoginClick = (state: State, action: AnyAction) => {
  const logoutAction = logout.pending.match(action)

  if (promptLogin.pending.match(action) || logoutAction) {
    return {
      evid: 218,
      button_name: logoutAction ? 'logout' : 'login',
      event_id: state.event.id,
      origin: 'schedule', // TODO: add new 'item details' origin when schedule item page is developed
    }
  }
}

import {BI_ENDPOINTS} from '@wix/wix-events-commons-statics'
import {EventMap} from '../../../../commons/bi/interfaces'
import {setFilter, setLocation, toggleTag} from '../actions/filter'
import {logout, promptLogin} from '../actions/member'
import {schedulePageLoaded} from '../actions/page-loaded'
import {createBookmark, deleteBookmark, readDescriptionClicked, viewPersonalAgenda} from '../actions/schedule'
import {
  handleAgendaClick,
  handleMembersLoginClick,
  handleScheduleFilterApplied,
  handleScheduleItemDescriptionView,
  handleSchedulePageLoaded,
  handleScheduledItemBookmarkClick,
} from './handlers'

export const eventMap: EventMap = {
  [schedulePageLoaded.toString()]: handleSchedulePageLoaded,
  [setLocation.fulfilled.toString()]: handleScheduleFilterApplied,
  [toggleTag.fulfilled.toString()]: handleScheduleFilterApplied,
  [setFilter.fulfilled.toString()]: handleScheduleFilterApplied,
  [readDescriptionClicked.toString()]: handleScheduleItemDescriptionView,
  [createBookmark.pending.toString()]: handleScheduledItemBookmarkClick,
  [deleteBookmark.pending.toString()]: handleScheduledItemBookmarkClick,
  [viewPersonalAgenda.pending.toString()]: handleAgendaClick,
  [promptLogin.pending.toString()]: handleMembersLoginClick,
  [logout.pending.toString()]: handleMembersLoginClick,
  endpoint: BI_ENDPOINTS.EVENTS_UOU,
}
